var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('v-dialog',{attrs:{"max-width":"1000px","persistent":"","scrollable":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',{staticClass:"mb-2"},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.model.id ? "Edición" : "Creación")+" de Red Social ")])]),_c('v-card-text',[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 py-1"},[_c('validation-provider',{attrs:{"name":"Link Red Social","vid":"url","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Link Red Social","required":""},model:{value:(_vm.model.url),callback:function ($$v) {_vm.$set(_vm.model, "url", $$v)},expression:"model.url"}})]}}])})],1),_c('div',{staticClass:"col-12 d-flex flex-column mb-4"},[_c('label',{staticClass:"font-weight-bold black--text"},[_vm._v("Imagen icono de la red social")]),_c('label',{staticClass:"black--text"},[_vm._v("Debes cargar un archivo de tipo: jpeg, jpg, png o gif")])]),_c('div',{staticClass:"col-12 col-md-6 py-0"},[_c('file-chooser',{ref:"fileChooser",attrs:{"options":{
              height: '190px',
              description: 'Haz clic aquí para cargar el icono de la red social',
              regex: /\.(jpg|jpeg|png|gif)$/,
              error: 'Solo se permiten imagenes',
              fileSize: 2,
            }},on:{"input":_vm.onLoadIcon}})],1)])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveSocialMedia}},[_vm._v(" Aceptar ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }