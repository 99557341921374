var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('label',{staticClass:"label-chooser"},[_vm._v(_vm._s(_vm.label))]),_c('file-chooser',{ref:"fairImageFileChooser",attrs:{"options":{
          height: '110px',
          description: 'Haz click para cargar la imagen',
          regex: /\.(jpg|jpeg|png|gif)$/,
          error: 'Solo se permiten imagenes',
          fileSize: 2,
        }},on:{"input":_vm.onLoadImageUrl}})],1),_c('div',{staticClass:"col-2 d-flex justify-center align-center mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 custom-col"},[_c('v-switch',{staticClass:"ma-0",attrs:{"dense":"","hide-details":""},on:{"click":_vm.onChangeStatus},model:{value:(_vm.isActiveLocal),callback:function ($$v) {_vm.isActiveLocal=$$v},expression:"isActiveLocal"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTrash),expression:"showTrash"}],staticClass:"col-12 custom-col"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.onDeleteImage}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}])},[_c('span',[_vm._v("Eliminar Imagen")])])],1)])]),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":_vm.appChangeStatusDialogDescription(),"is-active":_vm.isConfirmChangeStatusActive},on:{"onClose":function($event){return _vm.closeChangeStatusModal()},"onConfirm":function($event){return _vm.changeStatusFairAppModule()}}}),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":_vm.appDeleteImageDialogDescription(),"is-active":_vm.isConfirmDeleteImage},on:{"onClose":function($event){return _vm.closeDeleteIMageModal()},"onConfirm":function($event){return _vm.deleteImage()}}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8 d-flex flex-column"},[_c('h5',[_vm._v("Titulo Interno: "),_c('span',{domProps:{"textContent":_vm._s(_vm.internalName)}})]),_c('h5',[_vm._v("Titulo Externo: "),_c('span',{domProps:{"textContent":_vm._s(_vm.externalName)}})])]),_c('div',{staticClass:"col-2 px-0 "},[_c('v-btn',{staticClass:"secondary black--text px-1 py-0",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('open-titles-modal')}}},[_vm._v("Editar")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }